(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('RegisterController', RegisterController);
    RegisterController.$inject = ['$state', '$stateParams', '$scope', 'VerificationCodeService', '$interval', 'MemberAccountService', 'MessageService', '$rootScope', 'AuthServerProvider', 'NewsService'];

    function RegisterController($state, $stateParams, $scope, VerificationCodeService, $interval, MemberAccountService, MessageService, $rootScope, AuthServerProvider, NewsService) {
        /**
         * Created by vimpans on 2019/6/19
         */
        var vm = $scope;

        vm.agree = false;
        vm.canVerify = true;
        vm.counter = 0;
        vm.readOnly = false

        vm.registerModel = {subfix: '@gmail.com'};
        if ($stateParams.email) {
            vm.registerModel.email = $stateParams.email;
        }
        if ($stateParams.code) {
            vm.registerModel.recommend = $stateParams.code;
            vm.readOnly = true
        }

        vm.getVerification = function () {
            if (!vm.registerModel.email) {return}
            // if (
            //     vm.registerModel.email.indexOf('@qq.') != -1 ||
            //     vm.registerModel.email.indexOf('@163.') != -1 ||
            //     vm.registerModel.email.indexOf('@126.') != -1
            // ) {
            //     MessageService.error('不支持该邮箱！');
            //     return
            // }
            VerificationCodeService.pv({email:vm.registerModel.email+vm.registerModel.subfix},function (res) {
                countDownAction();
            }, function (error) {
                MessageService.error('获取验证码失败 '+(error.data.detail||""));
            })
        };

        function countDownAction() {
            vm.canVerify = false;
            vm.counter = 60;
            var interval = $interval(function () {
                vm.counter --;
                if (vm.counter <= 0) {
                    vm.counter = 0;
                    vm.canVerify = true;
                    $interval.cancel(interval);
                }
            },1000);
        }

        vm.registerAction = function () {
            MemberAccountService.register({
                email: vm.registerModel.email + vm.registerModel.subfix,
                code: vm.registerModel.code,
                password: vm.registerModel.password,
                recommend: vm.registerModel.recommend
            }, function (res) {
                AuthServerProvider.storeAuthenticationToken(res.data.id_token, false);
                $rootScope.$broadcast('authenticationSuccess');
                window.location.reload();
            }, function (error) {
                console.log("error: ",error);
                MessageService.error("注册失败 ！"+(error.errmsg||''));
            });
        };

        vm.jumpNegotiate = function () {
            NewsService.firstByType({type: 'NEGOTIATE'}, function (res) {
                $state.go('article', {id: res.data.id});
            })
        }
    }
})();
