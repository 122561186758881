(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('NewsService', NewsService);

    NewsService.$inject = ['$resource'];

    function NewsService($resource) {
        var service = $resource('api/article/:param', {}, {
            'news': {method: 'GET', params: {param: 'news'}, isArray: false},
            'firstByType': {method: 'GET', params: {param: 'firstByType'}, isArray: false},
            'findOne': {method: 'GET', params: {param: 'get'}, isArray: false},
            'questions': {method: 'GET', params: {param: 'questions'}, isArray: false}
        });
        return service;
    }
})();
