(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('VerificationController', VerificationController);
    VerificationController.$inject = ['$state', '$scope', 'Funcs', 'VerificationService', 'MessageService', 'Principal'];

    function VerificationController($state, $scope, Funcs, VerificationService, MessageService, Principal) {
        /**
         * Created by vimpans on 2019/7/26
         */
        var vm = $scope;

        vm.userModel = {};
        Principal.identity().then(function (account) {
            vm.userModel = account.data;
            console.log('userModel',vm.userModel);
        });

        vm.credentialList = [
            {value:'IDCARD', name: '中国大陆身份证'},
            {value:'PASSPORT', name: '护照'}
        ];

        vm.verificationModel = {credentialsType: 'IDCARD', credentialsName: '中国大陆身份证'};

        vm.uploadProofImage = function (type) {
            Funcs.imageUpload(function (resp) {
                if (type === 'front') {
                    vm.verificationModel.frontImageUrl = resp.imageUrl;
                } else {
                    vm.verificationModel.backImageUrl = resp.imageUrl;
                }
            })
        };

        vm.reVerification = function () {
            vm.userModel.verification = null;
        };

        vm.saveAction = function () {
            if (!vm.verificationModel.frontImageUrl) {
                MessageService.error("证件照正面为必需 !");
                return;
            }
            if (vm.verificationModel.credentialsType === 'IDCARD' && !vm.verificationModel.backImageUrl) {
                MessageService.error("身份证正反面均为必需 !");
                return;
            }
            VerificationService.create(vm.verificationModel , function (res) {
                MessageService.success("认证信息已提交，请耐心等待...");
                Principal.identity(true);
                window.history.back();
            }, function (error) {
                MessageService.error("认证信息提交失败"+(error.data.detail || ''));
            })
        }
    }
})();
