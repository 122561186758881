(function () {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$rootScopeProvider'];

    /**
     * Created by vimpans on 2019/6/27
     */

    function stateConfig($stateProvider) {
        $stateProvider
            .state('info', {
                parent: 'module',
                url: '/info',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/my-info/my-info.html',
                        controller: 'MyInfoController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('kqbList', {
                parent: 'info',
                url: '/kqbs',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/kqb/kqb-list.html',
                        controller: 'KQBListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('summary', {
                parent: 'info',
                url: '/summary',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/summary/summary.html',
                        controller: 'SummaryController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoBlocks', {
                parent: 'info',
                url: '/blocks',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/blocks/blocks.html',
                        controller: 'BlocksController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('accountList', {
                parent: 'info',
                url: '/accounts?:tab',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/account-list/account-list.html',
                        controller: 'AccountListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoOrder', {
                parent: 'info',
                url: '/order',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/order/order.html',
                        controller: 'OrderController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoRent', {
                parent: 'info',
                url: '/rents',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/rent-list/rent-list.html',
                        controller: 'RentListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoProfit', {
                parent: 'info',
                url: '/profit',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/profit/profit.html',
                        controller: 'ProfitController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoEProfit', {
                parent: 'info',
                url: '/eprofit',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/e-profit/e-profit.html',
                        controller: 'EProfitController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoProfile', {
                parent: 'info',
                url: '/profile',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/profile/profile.html',
                        controller: 'ProfileController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoTakein', {
                parent: 'info',
                url: '/takein',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/takein/takein.html',
                        controller: 'TakeinController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('infoTakeout', {
                parent: 'info',
                url: '/takeout?:ct',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/takeout/takeout.html',
                        controller: 'TakeoutController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('verification', {
                parent: 'info',
                url: '/verification',
                data: {
                    authorities: []
                },
                views: {
                    'info': {
                        templateUrl: 'app/my-info/verification/verification.html',
                        controller: 'VerificationController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
