(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('AboutusController', AboutusController);
    AboutusController.$inject = ['$state', '$scope', 'NewsService'];

    function AboutusController($state, $scope, NewsService) {
        /**
         * Created by vimpans on 2019/7/15
         */
        initDataAction();
        function initDataAction() {
            NewsService.firstByType({type: 'ABOUTUS'},function (res) {
                if (res && res.data && res.data.content) {
                    $("#aboutus-content").html(res.data.content);
                }
            })
        }
    }
})();
