(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProfileHeaderController', ProfileHeaderController);
    ProfileHeaderController.$inject = ['$state', '$scope','$uibModalInstance','Upload','entity', '$rootScope', 'Account', 'MessageService'];

    function ProfileHeaderController($state, $scope, $uibModalInstance,Upload, entity, $rootScope, Account, MessageService) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;
        vm.user = entity;

        vm.dismissAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.originImage = null;
        vm.cropImage = null;
        vm.transing = false;
        vm.selectImage = function () {
            var file = document.querySelector('input[type=file]').files[0];
            if (file) {
                vm.originImage = file;
            }
        };

        vm.imageUrl = "";
        vm.saveAction = function () {
            if (vm.transing) {return}
            var file = dataURLtoFile($('#resultImg')[0].currentSrc, vm.originImage.name);
            if (!file) {return}
            vm.transing = true;

            Upload.upload({
                url: 'api/files/user/upload',
                data: {file: file}
            }).then(function (resp) {
                vm.transing = false;
                vm.imageUrl = "api/files?accessPath="+resp.data.data.path;
                validate();
            });
        };
        function dataURLtoFile(dataurl, filename) {
            var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], filename, {type:mime});
        }
        function validate(){
            if (vm.imageUrl) {
                vm.user.imageUrl = vm.imageUrl;
                Account.update(vm.user, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            MessageService.success("更改头像成功");
            $rootScope.$broadcast('changeUserLogo', vm.user.imageUrl);
            $uibModalInstance.close();
            // $state.reload();
        }

        function onSaveError () {
            MessageService.error("更改头像失败，请联系管理员。");
        }
    }
})();
