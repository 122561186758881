(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ARecommendController', ARecommendController);
    ARecommendController.$inject = ['$state', '$scope', '$stateParams'];

    function ARecommendController($state, $scope, $stateParams) {
        /**
         * Created by vimpans on 2019/7/27
         */
        var vm = $scope;
        vm.recommendCode = $stateParams.code;

    }
})();
