(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('RentService', RentService);

    RentService.$inject = ['$resource'];

    function RentService($resource) {
        var service = $resource('api/rent/:param', {}, {
            'query': {method: 'GET', params: {param: 'list'}, isArray: false},
            'statistics': {method: 'GET', params: {param: 'statistics'}, isArray: false}
        });
        return service;
    }
})();
