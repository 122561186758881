(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('AnoteService', AnoteService);

    AnoteService.$inject = ['$resource'];

    function AnoteService($resource) {
        var service = $resource('api/anote/:param', {}, {
            'query': {method: 'GET', params: {param: 'list'}, isArray: false}
        });
        return service;
    }
})();
