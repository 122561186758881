(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ArticleController', ArticleController);
    ArticleController.$inject = ['$state', '$scope', 'NewsService', '$stateParams'];

    function ArticleController($state, $scope, NewsService, $stateParams) {
        /**
         * Created by vimpans on 2019/7/17
         */
        var vm = $scope;

        vm.articleModel = {};
        initDataAction();
        function initDataAction() {
            NewsService.findOne({id: $stateParams.id}, function (res) {
                vm.articleModel = res.data;
                if (vm.articleModel.redirect) {
                    $state.go(vm.articleModel.redirectUrl);
                }
                $('#content').html(vm.articleModel.content);
            })
        }
    }
})();
