(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('AddressBloService', AddressBloService);

    AddressBloService.$inject = ['$resource'];

    function AddressBloService($resource) {
        var service = $resource('api/addressblo/:param', {}, {
            'create': {method: 'POST', params: {param: 'create'}, isArray: false},
            'update': {method: 'POST', params: {param: 'update'}, isArray: false},
            'findByType': {method: 'GET', params: {param: 'findByType'}, isArray: false}
        });
        return service;
    }
})();
