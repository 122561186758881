(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('ProductionManagerService', ProductionManagerService);

    ProductionManagerService.$inject = ['$resource'];

    function ProductionManagerService($resource) {
        var service = $resource('api/production/:param', {}, {
            'query': {method: 'GET', params: {param: 'query'}, isArray: false},
            'get': {method: 'GET', params: {param: 'get'}, isArray: false}
        });
        return service;
    }
})();
