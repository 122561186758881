(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('DownloadController', DownloadController);
    DownloadController.$inject = ['$state', '$scope'];

    function DownloadController($state, $scope) {
        /**
         * Created by vimpans on 2019/7/27
         */
        var vm = $scope;
        vm.downloadApp = function () {
            if (isWeiXin()) {
                alert("请点击右上角，使用 系统浏览器 访问后下载！");
            } else {
                var a = $('<a download href="http://luckyhashglobal.com/download/latest0825.apk" target="_blank"></a>');
                a[0].click();
            }
        };

        function isWeiXin(){
            var ua = window.navigator.userAgent.toLowerCase();
            return !!ua.match(/micromessenger/i);
        }
    }
})();
