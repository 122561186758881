(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('BlocksController', BlocksController);
    BlocksController.$inject = ['$state', '$scope', 'Principal'];

    function BlocksController($state, $scope, Principal) {
        /**
         * Created by vimpans on 2019/6/27
         */
        var vm = $scope;

        vm.userModel = {};

        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                vm.userModel.useableAmount = 0;
                for (var i=0; i<vm.userModel.wallets.length; i++) {
                    var w = vm.userModel.wallets[i];
                    vm.userModel.useableAmount += w.radioAmount;
                    if (w.coinType === 'BTC') {
                        vm.userModel.btcAmount = w.amout;
                    }
                    if (w.coinType === 'USDT') {
                        vm.userModel.usdtAmount = w.amout;
                    }
                }
            })
        }
    }
})();
