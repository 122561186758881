(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('TakeoutController', TakeoutController);
    TakeoutController.$inject = ['$state', '$scope', 'NoteService', 'MessageService', 'Principal', 'VerificationCodeService', '$interval', '$stateParams'];

    function TakeoutController($state, $scope, NoteService, MessageService, Principal, VerificationCodeService, $interval, $stateParams) {
        /**
         * Created by vimpans on 2019/7/23
         */
        var vm = $scope;

        vm.userModel = {};
        vm.btcWallet = {};
        vm.usdtWallet = {};
        vm.canVerify = true;
        vm.counter = 0;

        vm.coinTypeList = ['BTC', 'USDT'];
        vm.canChoose = true;

        vm.takeoutModel = {coinType: 'BTC'};

        if ($stateParams.ct && vm.coinTypeList.indexOf($stateParams.ct)!==-1) {
            vm.takeoutModel = {coinType: $stateParams.ct};
            vm.canChoose = false;
        }

        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                for (var i=0; i<vm.userModel.wallets.length; i++) {
                    var wallet = vm.userModel.wallets[i];
                    if (wallet.coinType === 'BTC') {
                        vm.btcWallet = wallet;
                    } else if (wallet.coinType === 'USDT') {
                        vm.usdtWallet = wallet;
                    }
                }

                // if (!vm.userModel.verification || !vm.userModel.verification.finished) {
                //     $state.go("verification");
                //     MessageService.error("请先完成实名认证!");
                // }
            })
        }

        vm.getVerification = function () {
            VerificationCodeService.gv({},function (res) {
                countDownAction();
            }, function (error) {
                MessageService.error('获取验证码失败 '+(error.data.detail||""));
            })
        };

        function countDownAction() {
            vm.canVerify = false;
            vm.counter = 60;
            var interval = $interval(function () {
                vm.counter--;
                if (vm.counter <= 0) {
                    vm.counter = 0;
                    vm.canVerify = true;
                    $interval.cancel(interval);
                }
            }, 1000);
        }

        vm.takeOutAction = function () {
            NoteService.takeout(vm.takeoutModel, function (res) {
                MessageService.success("提现审核提交成功, 请耐心等待...");
                vm.takeoutModel = {coinType: 'BTC'};
                $state.go('accountList', {tab: 'CASHOUT'});
            }, function (error) {
                MessageService.error("提现审核提交失败 "+(error.data.detail||""));
            })
        }
    }
})();
