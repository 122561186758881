(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('TakeinController', TakeinController);
    TakeinController.$inject = ['$state', '$scope', 'NoteService', 'Funcs', 'MessageService', 'Principal'];

    function TakeinController($state, $scope, NoteService, Funcs, MessageService, Principal) {
        /**
         * Created by vimpans on 2019/7/17
         */
        var vm = $scope;

        vm.tab = "BTC";
        vm.switchTab = function (coinType) {
            vm.tab = coinType;
        };
        vm.userModel = {};

        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
            })
        }

        vm.coinTypeList = ['BTC', 'USDT'];

        vm.takeinModel = {coinType: 'BTC'};

        vm.copyText = function (t) {
            console.log('copy text', t);
        };

        vm.uploadProofImage = function () {
            Funcs.imageUpload(function (resp) {
                vm.takeinModel.proofImageUrl = resp.imageUrl
            })
        };

        vm.takeInAction = function () {
            NoteService.takein(vm.takeinModel, function (res) {
                MessageService.success("充值审核提交成功, 请耐心等待...");
                vm.takeinModel = {coinType: 'BTC'};
                $state.go('accountList', {tab: 'CASHIN'});
            }, function (error) {
                MessageService.error("充值审核提交失败 "+(error.data.detail||""));
            })
        }
    }
})();
