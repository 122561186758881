(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('OrderController', OrderController);
    OrderController.$inject = ['$state', '$scope', 'OrderService', 'MessageService'];

    function OrderController($state, $scope, OrderService, MessageService) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;
        vm.tab = 'CREATED';

        vm.orderList = [];

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0
        };

        vm.switchTab = function (operation) {
            vm.tab = operation;
            initDataAction();
        };

        initDataAction();
        function initDataAction() {
            OrderService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                type: vm.tab
            }, function (res) {
                vm.orderList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        vm.pageChangeAction = function () {
            initDataAction();
        };

        vm.finishOrder = function (id) {
            OrderService.finish({id: id}, function (res) {
                MessageService.success("恭喜，订单已完成!");
                initDataAction();
            }, function (error) {
                MessageService.error("抱歉，订单已完成失败" + (error.data.detail||''));
            })
        }
    }
})();
