(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProductListController', ProductListController);
    ProductListController.$inject = ['$state', '$scope', 'ProductionManagerService'];

    function ProductListController($state, $scope, ProductionManagerService) {
        /**
         * Created by vimpans on 2019/6/30
         */
        var vm = $scope;

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        vm.productList = [];
        initToday();
        initProductionData();
        function initToday() {
            var today = new Date();
            today.setHours(0);
            today.setSeconds(0);
            today.setMinutes(0);
            today.setDate(today.getDate()+1);
            vm.startTime = today;
        }
        function initProductionData() {
            ProductionManagerService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size
            }, function (res) {
                vm.productList = res.data.map(function (val) {
                    val.moreProfit = (val.moreCalcuability||0)+(val.floatCalcuability||0);
                    //TODO right=0
                    // val.lastCalcuability = 10;
                    var usedFloatCalcuability = val.totalCalcuability-val.lastCalcuability;
                    var usedPercent = usedFloatCalcuability/val.totalCalcuability;
                    if (usedPercent<0) {usedPercent = 0}
                    if (usedPercent>1){usedPercent = 1}
                    val.usedFloatCalcuability = Math.ceil(usedFloatCalcuability);
                    val.usedPercent = Math.ceil(usedPercent*100);
                    return val;
                });
                vm.pageparams.count = res.info.count;
            });
        }

        vm.pageChangeAction = function () {
            initProductionData();
        };
    }
})();
