(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProfileNicknameController', ProfileNicknameController);
    ProfileNicknameController.$inject = ['$state', 'entity', '$scope', '$uibModalInstance', 'Account', '$rootScope', 'MessageService'];

    function ProfileNicknameController($state, entity, $scope, $uibModalInstance, Account, $rootScope, MessageService) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;

        vm.user = entity;

        vm.dismissAction = function () {
            $uibModalInstance.dismiss();
        };

        vm.saveAction = function () {
            Account.update(vm.user, function (res) {
                MessageService.success("更改昵称成功");
                $rootScope.$broadcast('changeUserLogo', vm.user.imageUrl);
                $uibModalInstance.close();
            }, function (error) {
                MessageService.error("更改昵称失败"+(error.data.detail||""));
            })
        }
    }
})();
