(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('WalletService', WalletService);

    WalletService.$inject = ['$resource'];

    function WalletService($resource) {
        var service = $resource('api/members/:param', {}, {
            'subordinates': {method: 'GET', params: {param: 'subordinates'}, isArray: false}
        });
        return service;
    }
})();
