(function () {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', '$rootScopeProvider'];

    /**
     * Created by vimpans on 2019/6/30
     */

    function stateConfig($stateProvider) {
        $stateProvider
            .state('productList', {
                parent: 'module',
                url: '/product-list',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/product-list/product-list.html',
                        controller: 'ProductListController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
    }
})();
