(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ModuleController', ModuleController);
    ModuleController.$inject = ['$state', '$scope', 'Principal', 'AuthServerProvider', '$rootScope'];

    function ModuleController($state, $scope, Principal, AuthServerProvider, $rootScope) {
        /**
         * Created by vimpans on 2019/6/17
         */
        var vm = $scope;

        vm.currentUrl = null;

        //配置导航栏透明router表
        vm.transparentList = ['/home'];

        vm.transparent = false;

        $rootScope.$on('$stateChangeStart', function (event, to) {
            checkTransparent(to.url);
        });

        checkTransparent($state.current.url);
        function checkTransparent(state) {
            vm.currentUrl = state;
            if (vm.transparentList.indexOf(state) !== -1) {
                checkScroll();
                window.onscroll = function (ev) {
                    checkScroll(true);
                }
            } else {
                vm.transparent = false;
                window.onscroll = null;
            }
        }
        function checkScroll(apply) {
            var t = document.documentElement.scrollTop || document.body.scrollTop;
            var total = document.documentElement.clientWidth * 0.41 - 85;
            vm.transparent = t<total;
            if (apply) {vm.$apply()}
        }

        vm.userModel = null;
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
            })
        }

        vm.downloadApp = function () {
            if (isWeiXin()) {
                alert("请点击右上角，使用 系统浏览器 访问后下载！");
            } else {
                var a = $('<a download href="http://luckyhashglobal.com/download/latest0825.apk" target="_blank"></a>');
                a[0].click();
            }
        };

        vm.logoutAction = function () {
            AuthServerProvider.logout();
            window.location.reload();
        };

        function isWeiXin(){
            var ua = window.navigator.userAgent.toLowerCase();
            return !!ua.match(/micromessenger/i);
        }
    }
})();
