(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('MyInfoController', MyInfoController);
    MyInfoController.$inject = ['$state', '$scope', 'Principal', '$rootScope'];

    function MyInfoController($state, $scope, Principal, $rootScope) {
        /**
         * Created by vimpans on 2019/6/27
         */
        var vm = $scope;

        vm.currentUrl = null;

        initUserInfo();
        function initUserInfo(force) {
            Principal.identity(force).then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
            })
        }

        $rootScope.$on('$stateChangeStart', function (event, to) {
            checkCurrentUrl(to.url);
        });

        $rootScope.$on('changeUserLogo', function (event) {
            initUserInfo(true)
        });

        checkCurrentUrl($state.current.url);
        function checkCurrentUrl(state) {
            vm.currentUrl = state.split('?')[0];
            // console.log('current url-',vm.currentUrl);
        }
    }
})();
