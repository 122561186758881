(function () {
    'use strict';
    angular
        .module('gwApp')
        .directive('vpRadio', vpRadio);

    function vpRadio() {
        return {
            restrict: 'E',
            terminal: false,
            replace:true,
            template: function(tElement, tAttrs){
                var template =  "<span ng-click='vpRadioClick()' class='radio-container'>" +
                    "<span class='iconfont radio-style' ng-class=\"{'icon-radio-active':vpModel,'icon-radio-normal':!vmModel}\"></span>" +
                    "<span>"+tElement[0].innerText+"</span>" +
                    "</span>";
                return template;
            },
            scope: {
                vpModel: '=',
                vpChange: '&'
            },
            link: function ($scope) {
                var s = $scope;
                if (!s.vpModel) {s.vpModel = false}
                s.vpRadioClick = function () {
                    s.vpModel = !s.vpModel;
                    setTimeout(function () {
                        s.vpChange();
                    });
                };
            }
        };
    }
})();
