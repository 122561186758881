(function() {
    'use strict';
    angular
        .module('gwApp')
        .directive('page', page);

    page.$inject = ['MessageService', '$timeout'];

    function page(MessageService, $timeout){
        var directive = {
            restrict:"EA",//指令调用模式
            priority: 0,//指令优先级
            terminal: false,
            replace:true,
            template: function(tElement, tAttrs){
                var template =  "<div style='padding-top: 20px; font-weight: 700'>"+
                    "<div style='width:{{pageList.length*40}}px;height:100%;display: inline-block;'>"+
                    "<div style='float:left;margin-top: -4px;'>"+
                    "<button class='pageB' ng-class='{pageColor:pageCp===p}' ng-click='stepTo(p);' ng-repeat='p in pageList track by $index'>{{p}}</button>"+
                    "</div>"+
                    "</div>"+
                    "<p style='margin: 10px 0 0 0'>共{{totalPages}}页，{{pageCount}}条记录</p>"+
                    "</div>";
                return template;
            },
            scope: {
                pageCp:'=',
                pagePs:'=',
                pageCount:'=',
                pageChange:'&'
            },
            controller: ['$scope','$element','$attrs',function($scope, $element, $attrs) {
                var vm = $scope;
                vm.sizeList = [
                    {name:"10条/页",code:10},
                    {name:"15条/页",code:15},
                    {name:"20条/页",code:20}
                ];
                vm.stepPage = null;
                vm.totalPages = 1;

                vm.$watch('pageCount',function () {
                    vm.totalPages = 1;
                    if (vm.pageCount && vm.pagePs) {
                        vm.totalPages = Math.ceil(vm.pageCount/vm.pagePs)===0?1:Math.ceil(vm.pageCount/vm.pagePs);
                        setTimeout(function () {
                            generatorPageList();
                        })
                    }
                });
                vm.$watch('pagePs',function () {
                    vm.totalPages = 1;
                    if (vm.pageCount && vm.pagePs) {
                        vm.totalPages = Math.ceil(vm.pageCount/vm.pagePs)===0?1:Math.ceil(vm.pageCount/vm.pagePs);
                        setTimeout(function () {
                            generatorPageList();
                        })
                    }
                });

                function generatorPageList() {
                    if (!vm.totalPages) {
                        return;
                    }
                    if(vm.totalPages<=6){
                        vm.pageList = [];
                        for(var i=0; i<vm.totalPages; i++) {
                            vm.pageList.push(i+1);
                        }
                    }else{
                        if(vm.pageCp<=2){
                            vm.pageList = [1,2,3,"...",vm.totalPages-1,vm.totalPages];
                        }else{
                            if(vm.totalPages-vm.pageCp<2){
                                vm.pageList = [1,2,"...",vm.totalPages-2,vm.totalPages-1,vm.totalPages];
                            }else{
                                vm.pageList = [1,"...",vm.pageCp-1,vm.pageCp,vm.pageCp+1,"...",vm.totalPages];
                            }
                        }
                    }
                }
                generatorPageList();

                vm.stepTo =  function(count){
                    if(count==="..."){
                        return;
                    }
                    if(count!=null&&count!==''){
                        if(count>vm.totalPages){
                            count = vm.totalPages;
                        }
                        vm.pageCp = count;
                        vm.stepPage = null;
                        generatorPageList();
                        $timeout(function () {
                            vm.pageChange();
                        });
                    }else{
                        generatorPageList();
                        $timeout(function () {
                            vm.pageChange();
                        });
                    }
                };
                vm.resetMin = function(direction){
                    if(direction==='left'){
                        if(vm.pageCp>1){
                            vm.pageCp--;
                            generatorPageList();
                            $timeout(function () {
                                vm.pageChange();
                            });
                        }
                    }else{
                        if(vm.pageCp<vm.totalPages){
                            vm.pageCp++;
                            generatorPageList();
                            $timeout(function () {
                                vm.pageChange();
                            });
                        }
                    }
                };
                vm.inputKeyUp = function (e) {
                    if (e.keyCode === 13) {
                        vm.stepTo(vm.stepPage);
                    }
                }
            }],
            link: function(scope, element, attrs, SomeController) {

            }
        };

        return directive;
    }
})();
