(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SecurityController', SecurityController);
    SecurityController.$inject = ['$state', '$scope'];

    function SecurityController($state, $scope) {
        /**
         * Created by vimpans on 2019/7/16
         */

    }
})();
