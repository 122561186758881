(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProductController', ProductController);
    ProductController.$inject = ['$state', '$scope', '$stateParams', 'ProductionManagerService', 'OrderService', 'Principal', 'MessageService', '$rootScope', 'AuthServerProvider', 'MemberAccountService'];

    function ProductController($state, $scope, $stateParams, ProductionManagerService, OrderService, Principal, MessageService, $rootScope, AuthServerProvider, MemberAccountService) {
        /**
         * Created by vimpans on 2019/7/17
         */
        var vm = $scope;

        vm.product = {};

        initToday();
        function initToday() {
            var today = new Date();
            today.setHours(0);
            today.setSeconds(0);
            today.setMinutes(0);
            today.setDate(today.getDate()+1);
            vm.startTime = today;
        }
        initDataAction();
        function initDataAction() {
            ProductionManagerService.get({id: $stateParams.id}, function (res) {
                vm.product = res.data;

                if (vm.product.lastCalcuability <= 0) {
                    $state.gp('product');
                    MessageService.error("该商品目前正在补货中...");
                }

                var usedFloatCalcuability = vm.product.totalCalcuability-vm.product.lastCalcuability;
                var usedPercent = usedFloatCalcuability/vm.product.totalCalcuability;
                if (usedPercent<0) {usedPercent = 0}
                if (usedPercent>1){usedPercent = 1}
                vm.product.usedFloatCalcuability = Math.ceil(usedFloatCalcuability);
                vm.product.usedPercent = Math.ceil(usedPercent*100);
                // initOrderAction();
            })
        }
        vm.orderList = [];
        function initOrderAction() {
            OrderService.latest({id: vm.product.id}, function (res) {
                vm.orderList = res.data.map(function (val) {
                    var reg = /^(\d{3})\d*(\d{4})$/;
                    val.username = val.username.replace(reg,'$1****$2');
                    return val;
                })
            })
        }
        vm.userModel = null;
        vm.btcWallet = {};
        vm.usdtWallet = {};
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                for (var i=0; i<vm.userModel.wallets.length; i++) {
                    var wallet = vm.userModel.wallets[i];
                    if (wallet.coinType === 'BTC') {
                        vm.btcWallet = wallet;
                    } else if (wallet.coinType === 'USDT') {
                        vm.usdtWallet = wallet;
                    }
                    console.log(wallet);
                }
            })
        }

        vm.buyModel = {coinType: 'USDT'};
        vm.createOrder = function () {
            OrderService.create({
                productionId: vm.product.id,
                promoteActivityId: vm.product.activity?vm.product.activity.id:null,
                quantity: vm.buyModel.quantity,
                coinType: vm.buyModel.coinType
            }, function (res) {

                if (res && res.data) {
                    OrderService.finish({id: res.data.id}, function (res) {
                        MessageService.success("下单成功!");
                    }, function (error) {
                        MessageService.error("下单失败 "+(error.data.detail||""));
                    })
                } else {
                    MessageService.error("下单失败!");
                }

            }, function (error) {
                MessageService.error("下单失败 "+(error.data.detail||""));
            })
        };

        vm.loginModel = {rememberMe:false};

        vm.loginAction = function () {
            MemberAccountService.login(vm.loginModel, function (res) {
                AuthServerProvider.storeAuthenticationToken(res.id_token, false);
                $rootScope.$broadcast('authenticationSuccess');
                window.location.reload();
            }, function (error) {
                MessageService.error("登录失败 ！"+(error.data.detail||''));
            });
        }
    }
})();
