(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('HomeController', HomeController);
    HomeController.$inject = ['$state', '$scope', 'AppOperationService', 'Principal'];

    function HomeController($state, $scope, AppOperationService, Principal) {
        /**
         * Created by vimpans on 2019/6/17
         */
        var vm = $scope;

        vm.userModel = null;
        vm.phone = null;
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
            })
        }

        vm.myInterval = 5000;
        vm.noWrapSlides = false;
        vm.active = 0;

        vm.homeModel = {};

        initToday();
        function initToday() {
            var today = new Date();
            today.setHours(0);
            today.setSeconds(0);
            today.setMinutes(0);
            today.setDate(today.getDate()+1);
            vm.startTime = today;
        }

        initHomePageData();
        function initHomePageData() {
            AppOperationService.homepage({}, function (res) {
                vm.homeModel = res.data;
                vm.homeModel.latestProductions = vm.homeModel.latestProductions.map(function (val) {
                    var usedFloatCalcuability = val.totalCalcuability-val.lastCalcuability;
                    var usedPercent = usedFloatCalcuability/val.totalCalcuability;
                    if (usedPercent<0) {usedPercent = 0}
                    if (usedPercent>1){usedPercent = 1}
                    val.usedFloatCalcuability = Math.ceil(usedFloatCalcuability);
                    val.usedPercent = Math.ceil(usedPercent*100);
                    return val;
                });
                vm.homeModel.oneBuyProductions = vm.homeModel.oneBuyProductions.map(function (val) {
                    var usedFloatCalcuability = val.totalCalcuability-val.lastCalcuability;
                    var usedPercent = usedFloatCalcuability/val.totalCalcuability;
                    if (usedPercent<0) {usedPercent = 0}
                    if (usedPercent>=1){usedPercent = 1}
                    val.usedFloatCalcuability = Math.ceil(usedFloatCalcuability);
                    val.usedPercent = usedPercent;
                    if (val.lastCalcuability <= 0) {
                        val.usedPercent = 0;
                    }
                    return val;
                });
                vm.homeModel.recommendProductions = vm.homeModel.recommendProductions.map(function (val) {
                    var usedFloatCalcuability = val.totalCalcuability-val.lastCalcuability;
                    var usedPercent = usedFloatCalcuability/val.totalCalcuability;
                    if (usedPercent<0) {usedPercent = 0}
                    if (usedPercent>1){usedPercent = 1}
                    val.usedFloatCalcuability = Math.ceil(usedFloatCalcuability);
                    val.usedPercent = usedPercent;
                    if (val.lastCalcuability <= 0) {
                        val.usedPercent = 0;
                    }
                    return val;
                });
            });
        }
    }
})();
