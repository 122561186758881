(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ResetPasswordController', ResetPasswordController);
    ResetPasswordController.$inject = ['$state', '$scope', 'MemberAccountService', 'MessageService'];

    function ResetPasswordController($state, $scope, MemberAccountService, MessageService) {
        /**
         * Created by vimpans on 2019/7/24
         */
        var vm = $scope;

        vm.resetModel = {};

        vm.resetAction = function () {
            MemberAccountService.reset(vm.resetModel, function (res) {
                MessageService.success('修改密码成功 ！');
                window.history.back();
            }, function (error) {
                MessageService.error('修改密码失败 '+(error.data.detail||""));
            })
        }
    }
})();
