(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('AppOperationService', AppOperationService);

    AppOperationService.$inject = ['$resource'];

    function AppOperationService($resource) {
        var service = $resource('api/client/:param', {}, {
            'homepage': {method: 'GET', params: {param: 'homepage'}, isArray: false}
        });
        return service;
    }
})();
