(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('VerificationCodeService', VerificationCodeService);

    VerificationCodeService.$inject = ['$resource'];

    function VerificationCodeService($resource) {
        var service = $resource('api/:param', {}, {
            'pv': {method: 'POST', params: {param: 'verification'}, isArray: false},
            'gv': {method: 'get', params: {param: 'verification'}, isArray: false}
        });
        return service;
    }
})();
