(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('EProfitController', EProfitController);
    EProfitController.$inject = ['$state', '$scope', 'RentService'];

    function EProfitController($state, $scope, RentService) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;

        vm.staticData = {};
        vm.rentList = [];

        initStaticsAction();
        function initStaticsAction() {
            RentService.statistics({}, function (res) {
                vm.staticData = res.data;
            })
        }

        vm.tab = null;
        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0
        };
        vm.switchTab = function (operation) {
            vm.tab = operation;
            initDataAction();
        };
        initDataAction();
        function initDataAction() {
            RentService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                rentType: vm.tab
            }, function (res) {
                vm.rentList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        vm.pageChangeAction = function () {
            initDataAction();
        };
    }
})();
