(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ProfileController', ProfileController);
    ProfileController.$inject = ['$state', '$scope', 'Principal', 'Account', 'MessageService', '$uibModal'];

    function ProfileController($state, $scope, Principal, Account, MessageService, $uibModal) {
        /**
         * Created by vimpans on 2019/6/28
         */
        var vm = $scope;

        vm.isMan = false;
        vm.isWoman = false;

        vm.userModel = null;
        initUserInfo();
        function initUserInfo(force) {
            Principal.identity(force).then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                if (vm.userModel.gender === 1) {
                    vm.isMan = true;
                } else if (vm.userModel.gender === 2) {
                    vm.isWoman = true;
                }
            })
        }
        vm.changeMan = function () {
            if (vm.isMan) {
                vm.user.gender = 1;
                Account.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.gender = 0;
                Account.update(vm.user, onSaveSuccess, onSaveError);
            }
        };
        vm.changeWoman = function () {
            if (vm.isMan) {
                vm.user.gender = 2;
                Account.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                vm.user.gender = 0;
                Account.update(vm.user, onSaveSuccess, onSaveError);
            }
        };
        function onSaveSuccess (result) {
            MessageService.success("信息编辑成功");
            initUserInfo(true);
        }

        function onSaveError (error) {
            MessageService.error("信息编辑失败"+(error.data.detail||''));
        }

        vm.changeImageUrl = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/my-info/profile/profile-header.html',
                controller: 'ProfileHeaderController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {imageUrl: vm.userModel.imageUrl};
                    }
                }
            }).result.then(function () {
                initUserInfo(true);
            });
        };

        vm.changeNickname = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/my-info/profile/profile-nickname.html',
                controller: 'ProfileNicknameController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {nickname: vm.userModel.nickname};
                    }
                }
            }).result.then(function () {
                initUserInfo(true);
            });
        };

        vm.resetPassword = function () {
            $uibModal.open({
                animation: true,
                keyboard: false,
                backdrop: 'static',
                templateUrl: 'app/my-info/profile/profile-nickname.html',
                controller: 'ProfileNicknameController',
                controllerAs: 'vm',
                size: 'md',
                resolve: {
                    entity: function () {
                        return {nickname: vm.userModel.nickname};
                    }
                }
            }).result.then(function () {
                initUserInfo(true);
            });
        }
    }
})();
