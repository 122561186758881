(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('LoginController', LoginController);
    LoginController.$inject = ['$state', '$scope', 'MemberAccountService', '$rootScope', 'MessageService', 'AuthServerProvider', 'NewsService'];

    function LoginController($state, $scope, MemberAccountService, $rootScope, MessageService, AuthServerProvider, NewsService) {
        /**
         * Created by vimpans on 2019/6/25
         */
        var vm = $scope;

        vm.loginModel = {rememberMe:false};

        vm.loginAction = function () {
            MemberAccountService.login(vm.loginModel, function (res) {
                AuthServerProvider.storeAuthenticationToken(res.id_token, false);
                // console.log(res);
                $rootScope.$broadcast('authenticationSuccess');
                window.location.reload();
            }, function (error) {
                MessageService.error("登录失败 ！"+(error.data.detail||''));
            });
        };
    }
})();
