(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('MemberAccountService', MemberAccountService);

    MemberAccountService.$inject = ['$resource'];

    function MemberAccountService($resource) {
        var service = $resource('api/member/:param', {}, {
            'register': {method: 'POST', params: {param: 'register'}, isArray: false},
            'login': {method: 'POST', params: {param: 'authenticate'}, isArray: false},
            'forgot': {method: 'POST', params: {param: 'forgotPassword'}, isArray: false},
            'reset': {method: 'POST', params: {param: 'resetPassword'}, isArray: false}
        });
        return service;
    }
})();
