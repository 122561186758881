(function() {
    'use strict';

    angular
        .module('gwApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider','$rootScopeProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('module', {
                parent: 'app',
                url: '/module',
                data: {
                    authorities: []
                },
                views: {
                    'index@': {
                        templateUrl: 'app/module/module.html',
                        controller: 'ModuleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate,$translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('register', {
                parent: 'module',
                url: '/register?:phone&:code',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/register/register.html',
                        controller: 'RegisterController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('login', {
                parent: 'module',
                url: '/login',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/login/login.html',
                        controller: 'LoginController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('home', {
                parent: 'module',
                url: '/home',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/home/home.html',
                        controller: 'HomeController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('product', {
                parent: 'module',
                url: '/product?:id',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/product/product.html',
                        controller: 'ProductController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('article', {
                parent: 'module',
                url: '/article?:id',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/article/article.html',
                        controller: 'ArticleController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('forgotPassword', {
                parent: 'module',
                url: '/forgotp',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/forgot-password/forgot-password.html',
                        controller: 'ForgotPasswordController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('resetPassword', {
                parent: 'module',
                url: '/restp?:id',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/reset-password/reset-password.html',
                        controller: 'ResetPasswordController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('document', {
                parent: 'module',
                url: '/document',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/document/document.html',
                        controller: 'DocumentController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })
            .state('download', {
                parent: 'module',
                url: '/download',
                data: {
                    authorities: []
                },
                views: {
                    'content': {
                        templateUrl: 'app/module/download/download.html',
                        controller: 'DownloadController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        return $translate.refresh();
                    }]
                }
            })

    }
})();
