(function () {
    'use strict';

    angular
        .module('gwApp')
        .factory('NoteService', NoteService);

    NoteService.$inject = ['$resource'];

    function NoteService($resource) {
        var service = $resource('api/anote/:param', {}, {
            'takein': {method: 'POST', params: {param: 'takein'}, isArray: false},
            'takeout': {method: 'POST', params: {param: 'takeout'}, isArray: false},
            'listKQB': {method: 'GET', params: {param: 'listKQB'}, isArray: false}
        });
        return service;
    }
})();
