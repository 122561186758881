(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('NewsController', NewsController);
    NewsController.$inject = ['$state', '$scope', 'NewsService'];

    function NewsController($state, $scope, NewsService) {
        /**
         * Created by vimpans on 2019/6/30
         */
        var vm= $scope;

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0,
            keyword: null
        };

        vm.newsList = [];
        initNewsData();
        function initNewsData() {
            NewsService.news({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size
            }, function (res) {
                vm.newsList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        vm.pageChangeAction = function () {
            initNewsData();
        }
    }
})();
