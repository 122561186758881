(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('AccountListController', AccountListController);
    AccountListController.$inject = ['$state', '$stateParams', '$scope', 'AnoteService', 'IncomesService', 'Principal'];

    function AccountListController($state, $stateParams, $scope, AnoteService, IncomesService, Principal) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;
        vm.tab = 'CASHIN';
        vm.userModel = null;

        if ($stateParams && $stateParams.tab) {
            vm.tab = $stateParams.tab;
        }

        vm.accountList = [];
        vm.commisionList = [];

        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0
        };

        vm.switchTab = function (operation) {
            if (vm.tab == operation) return;
            vm.tab = operation;
            if (operation == 'COMMISION') {
                queryCommision();
            } else {
                initDataAction();
            }
        };

        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                initDataAction();
            })
        }

        initDataAction();
        function initDataAction() {
            AnoteService.query({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                type: vm.tab
            }, function (res) {
                vm.accountList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        function queryCommision() {
            IncomesService.detail({
                page: vm.pageparams.page-1,
                size: vm.pageparams.size,
                memberId: vm.userModel.id
            }, function(res) {
                vm.commisionList = res.data;
                vm.pageparams.count = res.info.count;
            })
        }

        vm.pageChangeAction = function () {
            if (vm.tab == 'COMMISION') {
                queryCommision();
            } else {
                initDataAction();
            }
        }
    }
})();
