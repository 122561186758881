(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('ForgotPasswordController', ForgotPasswordController);
    ForgotPasswordController.$inject = ['$state', '$scope', 'MemberAccountService', 'MessageService', 'VerificationCodeService', '$interval'];

    function ForgotPasswordController($state, $scope, MemberAccountService, MessageService, VerificationCodeService, $interval) {
        /**
         * Created by vimpans on 2019/7/24
         */
        var vm = $scope;

        vm.forgotModel = {};

        vm.canVerify = true;
        vm.counter = 0;

        vm.getVerification = function () {
            if (!vm.forgotModel.phone) {return}
            VerificationCodeService.pv({phone:vm.forgotModel.phone},function (res) {
                countDownAction();
            }, function (error) {
                MessageService.error('获取验证码失败 '+(error.data.detail||""));
            })
        };

        function countDownAction() {
            vm.canVerify = false;
            vm.counter = 60;
            var interval = $interval(function () {
                vm.counter --;
                if (vm.counter <= 0) {
                    vm.counter = 0;
                    vm.canVerify = true;
                    $interval.cancel(interval);
                }
            },1000);
        }

        vm.forgotAction = function () {
            MemberAccountService.forgot(vm.forgotModel, function (res) {
                MessageService.success('密码重置成功 ！');
                $state.go('login');
            }, function (error) {
                MessageService.error('密码重置失败'+(error.data.detail||""));
            })
        }
    }
})();
