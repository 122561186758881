(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('KQBListController', KQBListController);
    KQBListController.$inject = ['$state', '$scope', 'WalletService', 'Principal'];

    function KQBListController($state, $scope, WalletService, Principal) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;

        vm.userModel = null;
        vm.memberList2 = []
        vm.memberList3 = []
        vm.memberSelectId = ''
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                initDataAction();
            })
        }
        function initDataAction() {
            WalletService.subordinates({memberId: vm.userModel.id}, function (res) {
                vm.memberList2 = (res.data || []).map(function (item) {
                    if (item.position == 'AREA_DIRECTOR') {
                        item.positionName = "区域总监"
                    } else if (item.position == 'SALES_MANAGER') {
                        item.positionName = "销售经理"
                    } else if (item.position == 'DIRECTOR') {
                        item.positionName = "总监"
                    } else {
                        item.positionName = "普通员工"
                    }
                    return item
                });
            })
        }
        vm.selecSubordinates = function(id) {
            if (vm.memberSelectId == id) return
            vm.memberSelectId = id
            WalletService.subordinates({memberId: id}, function (res) {
                vm.memberList3 = (res.data || []).map(function (item) {
                    if (item.position == 'AREA_DIRECTOR') {
                        item.positionName = "区域总监"
                    } else if (item.position == 'SALES_MANAGER') {
                        item.positionName = "销售经理"
                    } else if (item.position == 'DIRECTOR') {
                        item.positionName = "总监"
                    } else {
                        item.positionName = "普通员工"
                    }
                    return item
                });
            })
        }
    }
})();
