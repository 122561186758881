(function () {
    'use strict';
    angular
        .module('gwApp')
        .controller('SummaryController', SummaryController);
    SummaryController.$inject = ['$state', '$scope', 'IncomesService', 'Principal'];

    function SummaryController($state, $scope, IncomesService, Principal) {
        /**
         * Created by vimpans on 2019/7/16
         */
        var vm = $scope;

        vm.userModel = null;
        vm.summaryList = [];
        vm.pageparams = {
            page: 1,
            size: 10,
            count: 0
        };
        initUserInfo();
        function initUserInfo() {
            Principal.identity().then(function (account) {
                if(!account) {return}
                vm.userModel = account.data;
                initDataAction();
            })
        }
        function initDataAction() {
            IncomesService.summary({
                memberId: vm.userModel.id,
                page: vm.pageparams.page-1,
                size: vm.pageparams.size
            }, function (res) {
                vm.summaryList = res.data || [];
                vm.pageparams.count = res.info.count;
            })
        }
        vm.pageChangeAction = function () {
            initDataAction();
        }
    }
})();
